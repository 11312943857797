import { DrugPrescriptionDurationType } from '@services/vidal/enum/drug-prescription-duration-type';
import { DrugPrescriptionFrequencyType } from '@services/vidal/enum/drug-prescription-frequency-type';
import { Dosage, Period } from '@services/vidal/entities/dosage';

export class DrugPrescriptionLine {
    constructor(
        public drug: string,
        public unitId?: number,
        public dose?: number,
        public duration?: number,
        public durationType?: DrugPrescriptionDurationType,
        public frequencyType?: DrugPrescriptionFrequencyType,
        public dosages?: Dosage[],
        public period?: Period
    ) {}
}

export class DrugPrescriptionLines {
    prescriptions: DrugPrescriptionLine[];
}
