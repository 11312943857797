export enum DrugPrescriptionUnit {
    UI = 105,
    MG = 57,
    ML = 60,
    MICROGRAM = 5,
    G = 35,
    MUI = 51,
    MGH = 118,
    MGKG = 743
}
