import { Injectable } from '@angular/core';
import { EGender } from '@mapuilabs/hv-interfaces';
import { EIntervalUnit } from '@services/vidal/entities/dosage';
import { EVidalGalenicForm } from '@services/vidal/enum/EVidalGalenicForm';
import { DrugPrescriptionDurationType } from '@services/vidal/enum/drug-prescription-duration-type';
import { DrugPrescriptionFrequencyType } from '@services/vidal/enum/drug-prescription-frequency-type';
import { Gender } from '@services/vidal/enum/gender';
import { EDosageEquivalent, EDurationUnit, EUnitEquivalent } from '@shared/select-posology/posology.const';
import { DrugPrescriptionUnit } from './enum/drug-prescription-unit';

@Injectable({
    providedIn: 'root'
})
export class VidalMapper {
    mapToVidalGender(gender: EGender): string {
        switch (gender) {
            case EGender.male:
                return Gender.MALE;
            case EGender.female:
                return Gender.FEMALE;
            default:
                return Gender.UNKNOWN;
        }
    }

    mapToVidalIntervalUnitId(unit: EDurationUnit): EIntervalUnit {
        switch (unit) {
            case EDurationUnit.YEAR:
                return EIntervalUnit.YEAR;
            case EDurationUnit.MONTH:
                return EIntervalUnit.MONTH;
            case EDurationUnit.WEEK:
                return EIntervalUnit.WEEK;
            case EDurationUnit.SECOND:
                return EIntervalUnit.SECOND;
            case EDurationUnit.MINUTE:
                return EIntervalUnit.MINUTE;
            case EDurationUnit.DAY:
                return EIntervalUnit.DAY;
            case EDurationUnit.HOUR:
                return EIntervalUnit.HOUR;
        }
    }

    mapToVidalUnitId(unit: EDosageEquivalent | EUnitEquivalent): number {
        switch (unit) {
            case EDosageEquivalent.AMPOULE:
                return EVidalGalenicForm.AMPOULE;
            case EDosageEquivalent.APPLICATION:
                return EVidalGalenicForm.APPLICATION;
            case EDosageEquivalent.BAIN:
                return EVidalGalenicForm.BAIN;
            case EDosageEquivalent.BAIN_DE_BOUCHE:
                return EVidalGalenicForm.BAIN_DE_BOUCHE;
            case EDosageEquivalent.BATON:
                return EVidalGalenicForm.BATON;
            case EDosageEquivalent.BILLE:
                return EVidalGalenicForm.BILLE;
            case EDosageEquivalent.BOUFFEE:
                return EVidalGalenicForm.BOUFFEE;
            case EDosageEquivalent.CACHET:
                return EVidalGalenicForm.CACHET;
            case EDosageEquivalent.CAPSULE:
                return EVidalGalenicForm.CAPSULE;
            case EDosageEquivalent.CAPSULE_MOLLE:
                return EVidalGalenicForm.CAPSULE_MOLLE;
            case EDosageEquivalent.CATAPLASME:
                return EVidalGalenicForm.CATAPLASME;
            case EDosageEquivalent.CHAMP_MEDICAMENTEUX:
                return EVidalGalenicForm.CHAMP_MEDICAMENTEUX;
            case EDosageEquivalent.CIGARETTE:
                return EVidalGalenicForm.CIGARETTE;
            case EDosageEquivalent.COMPRESSE:
                return EVidalGalenicForm.COMPRESSE;
            case EDosageEquivalent.COMPRIME:
                return EVidalGalenicForm.COMPRIME;
            case EDosageEquivalent.CUILLERE_A_CAFE:
                return EVidalGalenicForm.CUILLERE_A_CAFE;
            case EDosageEquivalent.CUILLERE_A_DESSERT:
                return EVidalGalenicForm.CUILLERE_A_DESSERT;
            case EDosageEquivalent.CUILLERE_A_SOUPE:
                return EVidalGalenicForm.CUILLERE_A_SOUPE;
            case EDosageEquivalent.CUILLERE_MESURE:
                return EVidalGalenicForm.CUILLERE_MESURE;
            case EDosageEquivalent.DISPOSITIF:
                return EVidalGalenicForm.DISPOSITIF;
            case EDosageEquivalent.DISPOSITIF_INTRAUTERIN:
                return EVidalGalenicForm.DISPOSITIF_INTRAUTERIN;
            case EDosageEquivalent.DISPOSITIF_TRANSDERMIQUE:
                return EVidalGalenicForm.DISPOSITIF_TRANSDERMIQUE;
            case EDosageEquivalent.DOSE:
                return EVidalGalenicForm.DOSE;
            case EDosageEquivalent.DOSE_KG:
                return EVidalGalenicForm.DOSE_KG;
            // case EDosageEquivalent.DOSE_UNITAIRE:
            //     return EVidalGalenicForm.DOSE_UNITAIRE;
            case EDosageEquivalent.EMPLATRE:
                return EVidalGalenicForm.EMPLATRE;
            case EDosageEquivalent.EPONGE:
                return EVidalGalenicForm.EPONGE;
            case EDosageEquivalent.FILM_ORODISPERSIBLE:
                return EVidalGalenicForm.FILM_ORODISPERSIBLE;
            case EDosageEquivalent.FLACON:
                return EVidalGalenicForm.FLACON;
            case EDosageEquivalent.GAZE:
                return EVidalGalenicForm.GAZE;
            case EDosageEquivalent.GELULE:
                return EVidalGalenicForm.GELULE;
            case EDosageEquivalent.GOBELET:
                return EVidalGalenicForm.GOBELET;
            case EDosageEquivalent.GOMME:
                return EVidalGalenicForm.GOMME;
            // case EDosageEquivalent.GOUTTE:
            //     return EVidalGalenicForm.GOUTTE;
            // case EDosageEquivalent.GOUTTE_OEIL:
            //     return EVidalGalenicForm.GOUTTE_OEIL;
            case EDosageEquivalent.GRANULE:
                return EVidalGalenicForm.GRANULE;
            case EDosageEquivalent.IMPLANT:
                return EVidalGalenicForm.IMPLANT;
            case EDosageEquivalent.INHALATION:
                return EVidalGalenicForm.INHALATION;
            case EDosageEquivalent.INJECTION:
                return EVidalGalenicForm.INJECTION;
            case EDosageEquivalent.INSERT:
                return EVidalGalenicForm.INSERT;
            case EDosageEquivalent.INSTILLATION:
                return EVidalGalenicForm.INSTILLATION;
            case EDosageEquivalent.LAVEMENT:
                return EVidalGalenicForm.LAVEMENT;
            case EDosageEquivalent.LYOPHILISAT:
                return EVidalGalenicForm.LYOPHILISAT;
            case EDosageEquivalent.MATRICE:
                return EVidalGalenicForm.MATRICE;
            case EDosageEquivalent.MECHE:
                return EVidalGalenicForm.MECHE;
            case EDosageEquivalent.MESURE:
                return EVidalGalenicForm.MESURE;
            case EDosageEquivalent.OVULE:
                return EVidalGalenicForm.OVULE;
            case EDosageEquivalent.PANSEMENT:
                return EVidalGalenicForm.PANSEMENT;
            // case EDosageEquivalent.PANSEMENT_ADHESIF:
            //     return EVidalGalenicForm.PANSEMENT_ADHESIF;
            case EDosageEquivalent.PASTILLE:
                return EVidalGalenicForm.PASTILLE;
            case EDosageEquivalent.PATCH:
                return EVidalGalenicForm.PATCH;
            case EDosageEquivalent.PATE:
                return EVidalGalenicForm.PATE;
            // case EDosageEquivalent.PERFUSION:
            //     return EVidalGalenicForm.PERFUSION;
            case EDosageEquivalent.PILULE:
                return EVidalGalenicForm.PILULE;
            case EDosageEquivalent.POCHE:
                return EVidalGalenicForm.POCHE;
            case EDosageEquivalent.PULVERISATION:
                return EVidalGalenicForm.PULVERISATION;
            case EDosageEquivalent.RECIPIENT_UNIDOSE:
                return EVidalGalenicForm.RECIPIENT_UNIDOSE;
            case EDosageEquivalent.SACHET:
                return EVidalGalenicForm.SACHET;
            case EDosageEquivalent.SERINGUE_PRE_REMPLIE:
                return EVidalGalenicForm.SERINGUE_PRE_REMPLIE;
            case EDosageEquivalent.SHAMPOOING:
                return EVidalGalenicForm.SHAMPOOING;
            case EDosageEquivalent.SOLUTION:
                return EVidalGalenicForm.SOLUTION;
            case EDosageEquivalent.SUPPOSITOIRE:
                return EVidalGalenicForm.SUPPOSITOIRE;
            // case EDosageEquivalent.SYSTEME_DE_DIFFUSION_VAGINAL:
            //     return EVidalGalenicForm.SYSTEME_DE_DIFFUSION_VAGINAL;
            case EDosageEquivalent.TAMPON:
                return EVidalGalenicForm.TAMPON;
            case EDosageEquivalent.TIMBRE:
                return EVidalGalenicForm.TIMBRE;
            case EUnitEquivalent.MG:
                return DrugPrescriptionUnit.MG;
            case EUnitEquivalent.MICROGRAM:
                return DrugPrescriptionUnit.MICROGRAM;
            case EUnitEquivalent.MUI:
                return DrugPrescriptionUnit.MUI;
            case EUnitEquivalent.UI:
                return DrugPrescriptionUnit.UI;
            case EUnitEquivalent.G:
                return DrugPrescriptionUnit.G;
            case EUnitEquivalent.ML:
                return DrugPrescriptionUnit.ML;
            // case EUnitEquivalent.MLH:
            //     return DrugPrescriptionUnit.MLH;
            case EUnitEquivalent.MGH:
                return DrugPrescriptionUnit.MGH;
            case EUnitEquivalent.MGKG:
                return DrugPrescriptionUnit.MGKG;
            default:
                return EVidalGalenicForm.NON_RENSEIGNEE;
        }
    }

    mapToVidalDurationType(durationType: EDurationUnit): DrugPrescriptionDurationType {
        switch (durationType) {
            case EDurationUnit.MINUTE:
                return DrugPrescriptionDurationType.MINUTE;
            case EDurationUnit.HOUR:
                return DrugPrescriptionDurationType.HOUR;
            case EDurationUnit.DAY:
                return DrugPrescriptionDurationType.DAY;
            case EDurationUnit.WEEK:
                return DrugPrescriptionDurationType.WEEK;
            case EDurationUnit.MONTH:
                return DrugPrescriptionDurationType.MONTH;
            case EDurationUnit.YEAR:
                return DrugPrescriptionDurationType.YEAR;
            default:
                return null;
        }
    }

    mapToVidalFrequencyType(frequencyType: string): DrugPrescriptionFrequencyType {
        switch (frequencyType) {
            case EDurationUnit.DAY:
                return DrugPrescriptionFrequencyType.PER_DAY;
            default:
                return null;
        }
    }
}
