export enum EVidalGalenicForm {
    ADJUVANT = 4580,
    BATON = 367,
    BILLE = 3516,
    CACHET = 122,
    CAPSULE_MOLLE = 4030,
    CATAPLASME = 669,
    CHAMP_MEDICAMENTEUX = -1, // CHAMP ?
    CIGARETTE = 126,
    COLLE = 3842,
    COLLODION = 4581,
    COLLYRE = 3861,
    COMPRESSE = 128,
    COMPRIME = 129,
    CREME = 4090,
    DISPERSION = 4601,
    DISPOSITIF = 430,
    EMPLATRE = 670,
    EMULSION = 3318,
    EPONGE = 459,
    EQUIVALENT_TISSULAIRE_VIVANT = 4437,
    FEUILLET = -1, // FEUILLET_KERAT ?
    FILM = 460,
    GAZ = 3316,
    GAZE = 504,
    GEL = 3323,
    GELULE = 139,
    GENERATEUR_RADIOPHARMACEUTIQUE = 4538,
    GLOBULE = 140,
    GOMME = 142,
    GRANULE = 143,
    GRANULES = -1,
    IMPLANT = 522,
    INSERT = 527,
    LAQUE = 3911,
    LINIMENT = 4082,
    LIQUIDE = 3834,
    LOTION = 3871,
    LYOPHILISAT = 2010,
    MATRICE = 698,
    MICROSPHERE = -1, // ?
    MOUSSE = 4155,
    NON_RENSEIGNEE = -1,
    OVULE = 157,
    PANSEMENT = 550,
    PASTILLE = 159,
    PATE = 160,
    PILULE = 163,
    PLANTE = 3510,
    POMMADE = 4070,
    POUDRE = 3984,
    PRECURSEUR_RADIOPHARMACEUTIQUE = 3033,
    PREPARATION = 3507,
    SAVON = 3810,
    SHAMPOOING = 4448,
    SIROP = 3885,
    SOLUTION = 3841,
    SOLVANT = 3929,
    SUPPOSITOIRE = 170,
    SUSPENSION = 4119,
    TAMPON = 172,
    TIMBRE = 173,
    TROUSSE_RADIOPHARMACEUTIQUE = 3278,
    VERNIS = 3139,
    DISPOSITIF_INTRAUTERIN = 668,
    DISPOSITIF_TRANSDERMIQUE = 434,
    DOSE = 33,
    BOUFFEE = 121,
    CUILLERE_MESURE = 130,
    CUILLERE_A_CAFE = 131,
    CUILLERE_A_DESSERT = 132,
    CUILLERE_A_SOUPE = 133,
    GOBELET = 141,
    PULVERISATION = 166,
    MECHE = 538,
    MESURE = 539,
    RECIPIENT_UNIDOSE = 574,
    FILM_ORODISPERSIBLE = 710,
    DOSE_KG = 137,
    CAPSULE = 123,
    APPLICATION = 120,
    BAIN_DE_BOUCHE = 364,
    BAIN = 3886,
    LAVEMENT = 667,
    SACHET = 168,
    INJECTION = 146,
    PATCH = 3747,
    INSTILLATION = 528,
    AMPOULE = 119,
    INHALATION = 145,
    FLACON = 138,
    POCHE = 164,
    SERINGUE_PRE_REMPLIE = 586
}
