export class Dosage {
    dose: number;
    unitId: number;
    interval: Interval;
}

export class Interval {
    min: number;
    max: number;
    unitId: EIntervalUnit;
}

export enum EIntervalUnit {
    I_12_HOURS = 11,
    I_14_HOURS = 14,
    I_3_HOURS = 15,
    YEAR = 22,
    HOUR = 41,
    DAY = 44,
    MINUTE = 59,
    MONTH = 62,
    WEEK = 77,
    SECOND = 169
}

export class Period {
    startDate: Date;
    endDate: Date;
}
